<template>
  <v-app>
    <v-card class="mx-auto overflow-hidden" height="100%" width="100%">
      <v-system-bar color="primary darken-2"></v-system-bar>
      <v-app-bar color="primary darken-1" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- <v-toolbar-side-icon>
            <v-img class="mr-3" src="@/assets/logo-lave.png" height="30px" width="40px"></v-img>
          </v-toolbar-side-icon> -->
        <v-toolbar-title>
          <h4>Venta de Timbres</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="salir" v-if="logueado">
          <v-icon>logout</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" absolute temporary v-if="logueado">
        <v-list shaped>
          <template>
            <v-list-item :to="{ name: 'Home' }">
              <v-list-item-icon>
                <v-icon>home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Paquetes' }">
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Paquetes</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Historial' }">
              <v-list-item-icon>
                <v-icon>history</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Historial</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
    </v-card>
    <v-footer>
      <v-card
        flat
        tile
        width="100%"
        class="lighten-1 text-center"
        color="primary darken-2"
      >
        <v-card-text>
          <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Laudem Ave</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
      icons: ["home", "email", "language"],
    };
  },
  computed: {
    logueado() {
      return this.$store.state.usuario;
    },
    obtieneEmpresa() {
      return this.$store.state.usuario.empresa;
    },
    obtieneUsuario() {
      return this.$store.state.usuario.nombre;
    },
    esAdministrador() {
      return (
        this.$store.state.usuario &&
        this.$store.state.usuario.rol == "Administrador"
      );
    },
  },

  created() {
    this.$store.dispatch("autoLogin");
  },
  methods: {
    async salir() {
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://usuarios.contago.com.mx/api/Usuarios/actualizaSesion/" +
            this.$store.state.usuario.nombre +
            "/INACTIVA"
        );
        this.$store.dispatch("salir");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
