import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Login from '../components/Login/Login.vue'
import Paquetes from '../components/Paquetes/paquetes.vue'
import Historial from '../components/Historial/historial.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true, } },
  { path: '/Paquetes', name: 'Paquetes', component: Paquetes, meta: { libre: true, } },
  { path: '/Historial', name: 'Historial', component: Historial, meta: { libre: true, } },
  { path: '/Home', name: 'Home', component: Home, meta: { Administrador: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'Gasolineros') {
    if (to.matched.some(record => record.meta.Gasolineros)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})

export default router
