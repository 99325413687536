<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ textDialog }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- VISTA PREVIA DEL PDF -->
    <v-dialog v-model="dialogPdf" persistent width="1000px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
            fab
            dark
            small
            @click="
              (dialogPdf = false),
                (dialogComprobante = false),
                (dialog = false),
                (dialogFolio = false)
            "
            color="primary darken-1"
            ><v-icon>close</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-toolbar-title>Vista Previa</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <template v-if="opcionesPdf"> -->
          <template>
            <v-btn
              fab
              dark
              small
              color="primary darken-1"
              class="mx-2"
              @click="funcionDescargaComprobante()"
              ><v-icon>file_download</v-icon></v-btn
            >
            <v-btn
              fab
              dark
              small
              color="primary darken-1"
              class="mx-2"
              @click="funcionEnviarCorreo()"
              ><v-icon>email</v-icon></v-btn
            >
          </template>
        </v-toolbar>
        <v-card-text>
          <template>
            <div class="text-center">
              Página:
              <input
                v-model.number="pagePdf"
                type="number"
                style="width: 5em"
              />
              /{{ numPagesPdf }}
              <div style="width: 100%">
                <div
                  v-if="loadedRatio > 0 && loadedRatio < 1"
                  style="
                    background-color: green;
                    color: white;
                    text-align: center;
                  "
                  :style="{ width: loadedRatio * 100 + '%' }"
                >
                  {{ Math.floor(loadedRatio * 100) }}%
                </div>
                <pdf
                  style="border: 1px solid black"
                  ref="pdf"
                  :src="srcPdf"
                  :page="pagePdf"
                  :rotate="rotate"
                  @progress="loadedRatio = $event"
                  @link-clicked="page = $event"
                  @num-pages="numPagesPdf = $event"
                ></pdf>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogo Folio-->
    <v-dialog v-model="dialogFolio" persistent max-width="400px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>Número de referencia</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              absolute
              right
              style="margin-right: 20px"
              color="primary"
              elevation="2"
              fab
              dark
              small
              @click="dialogFolio = false"
              ><v-icon dark>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="ref"
            prepend-icon="person"
            label="Refrencia:"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="margin-bottom: 25px; margin-right: 20px"
            color="green"
            elevation="2"
            fab
            dark
            small
            @click="validarReferencia()"
            ><v-icon dark>done</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogo -->
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>Factura</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              absolute
              right
              style="margin-right: 20px"
              color="primary"
              elevation="2"
              fab
              dark
              small
              @click="(dialog = false), (dialogFolio = false)"
              ><v-icon dark>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-icon="person"
            readonly
            label="RFC:"
            v-model="this.$store.state.usuario.rfc"
          >
          </v-text-field>
          <v-text-field
            prepend-icon="person"
            readonly
            label="Nombre:"
            v-model="this.$store.state.usuario.empresa"
          >
          </v-text-field>
          <v-text-field
            prepend-icon="person"
            readonly
            label="Total:"
            v-model="total"
            prefix="$"
          >
          </v-text-field>
        </v-card-text>
        <v-alert text dense color="teal" icon="mdi-clock-fast" border="left">
          Si desea facturar indicar en el botón verde, en caso contrario botón
          rojo.
          <br />
          *Nota: En caso de no solicitar la factura en este momento, se
          facturara de manera automatica a publico en general, por lo que la
          factura no se podra requerir después.
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="margin-bottom: 25px; margin-right: 20px"
                color="red"
                elevation="2"
                fab
                dark
                small
                v-bind="attrs"
                v-on="on"
                @click="timbraPG()"
                ><v-icon dark>close</v-icon></v-btn
              >
            </template>
            <span>No facturar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="margin-bottom: 25px; margin-right: 20px"
                color="green"
                elevation="2"
                fab
                dark
                small
                v-bind="attrs"
                v-on="on"
                @click="timbra()"
                ><v-icon dark>done</v-icon></v-btn
              >
            </template>
            <span>Facturar</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar flat>
      <v-toolbar-title class="grey--text"> <h2>Paquetes</h2></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>help</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-title></v-card-title>
    <v-row>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#E60404">
          <v-toolbar-title>BASICO</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 30</h3>
          <h3>Precio: $278.40</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#E60404"
            dark
            @click="seleccionarPaquete(278.4, 'Paquete Basico con 30 timbres')"
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#F3282A">
          <v-toolbar-title>PRÁCTICO</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 60</h3>
          <h3>Precio: $417.60</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#F3282A"
            dark
            @click="
              seleccionarPaquete(417.6, 'Paquete Práctico con 60 timbres')
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#F57F01">
          <v-toolbar-title>COMERCIAL</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 120</h3>
          <h3>Precio: $661.20</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#F57F01"
            dark
            @click="
              seleccionarPaquete(661.2, 'Paquete Comercial con 120 timbres')
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#FF9816">
          <v-toolbar-title>EMPRESARIAL</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 250</h3>
          <h3>Precio: $1,015.00</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#FF9816"
            dark
            @click="
              seleccionarPaquete(1015, 'Paquete Empresarial con 250 timbres')
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <v-card-title><br /></v-card-title>
    <v-row>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#E60404">
          <v-toolbar-title>EMPRESARIAL PLUS</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 500</h3>
          <h3>Precio: $1,450.00</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#E60404"
            dark
            @click="
              seleccionarPaquete(
                1450,
                'Paquete Empresarial Plus con 500 timbres'
              )
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#F3282A">
          <v-toolbar-title>EMPRESARIAL PREMIUM</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 1,000</h3>
          <h3>Precio: $2,610.00</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#F3282A"
            dark
            @click="
              seleccionarPaquete(
                2610,
                'Paquete Empresarial Premium con 1000 timbres'
              )
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#F57F01">
          <v-toolbar-title>EMPRESARIAL PLATINO</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 2000</h3>
          <h3>Precio: $4,176.00</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#F57F01"
            dark
            @click="
              seleccionarPaquete(
                4176,
                'Paquete Empresarial Platino con 2000 timbres'
              )
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="mx-auto" width="280px" elevation="5">
        <v-toolbar dark color="#FF9816">
          <v-toolbar-title
            >EMPRESARIAL PLATINO <br />
            PLUS
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="justify-center">
          <h3>Timbres: 5,000</h3>
          <h3>Precio: $8,700.00</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            center
            rounded
            color="#FF9816"
            dark
            @click="
              seleccionarPaquete(
                8700,
                'Paquete Empresarial Platino Plus con 5000 timbres'
              )
            "
            ><v-icon style="padding-right: 10px; padding-left: 5px" dark
              >shopping_cart</v-icon
            >Comprar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <v-card-title><br /></v-card-title>
    <v-snackbar
      rounded="pill"
      shaped
      color="primary light"
      v-model="snackbar"
      :timeout="timeout"
      >{{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"
          >Cerrar</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>    
<script>
import axios from "axios";
import print from "print-js";
import pdf from "vue-pdf";

export default {
  components: {
    print,

    pdf: pdf,
  },
  data: () => ({
    loading: false,
    dialogFolio: false,
    dialog: false,
    dialogPdf: false,
    textDialog: null,
    error: null,
    timeout: 3000,
    snackbar: false,
    folio: "",
    rfc: "",
    nombre: "",
    total: "",
    pdf: pdf,
    concepto: "",
    ref: "",
    //DATOS DEL CORREO
    dialogCorreo: false,
    receptorCorreo: "",
    asunto: "Factura",
    mensajeCorreo: "",
    listArchivos: [],
    selectArchivo: {},
    //XML Y PDF DEL COMPROBANTE
    resultadoComprobante: {
      _xml: "",
      _pdf: "",
      _nombre: "",
    },
    //DIALOG PARA EL PDF
    dialogPdf: false,
    srcPdf: "",
    pagePdf: 1,
    numPagesPdf: 0,
    loadedRatio: 0,
    rotate: 0,
    opcionesPdf: false,
  }),
  computed: {},
  methods: {
    async validarReferencia() {
      try {
        let respuesta = await axios.post("api/Referencias/ValidaReferencia", {
          referencia: this.ref,
          monto: this.total,
        });
        let respuesta2 = await axios.post("api/Usuarios/ConfirmaCompra/", {
          idcliente: this.$store.state.usuario.idusuariosApp,
          referencia: this.ref,
          monto: this.total,
          rfc: this.$store.state.usuario.rfc,
          timbre: this.concepto,
        });

        this.dialog = true;
      } catch (err) {
        console.log(err.response.status);
        this.error =
          "Error, la referencia indicada, no existe o la compra ya fue realizada. Para mayor información comuniquese con su vendedor";
        this.snackbar = true;
        if (err.response.status == 500) {
          this.error = err.response;
          this.error = "Error al asignar timbres";
          this.snackbar = true;
        }
      }
    },

    funcionDescargaComprobante() {
      this.download(
        this.resultadoComprobante._pdf,
        this.resultadoComprobante._nombre,
        "pdf"
      );
      this.download(
        this.resultadoComprobante._xml,
        this.resultadoComprobante._nombre,
        "xml"
      );
      this.dialogFolio = false;
      this.dialog = false;
    },

    download(archivo, nombre, tipo) {
      var arrBuffer = this.base64ToArrayBuffer(archivo);
      var newBlob = new Blob([arrBuffer]);

      var data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.href = data;
      link.download = nombre + "." + tipo;
      link.click();
      window.URL.revokeObjectURL(data);
      link.remove();
    },

    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },

    //CORREOS
    funcionEnviarCorreo() {
      this.textDialog = "Enviando correo, espere...";
      this.loading = true;

      this.textSnackbar = "";
      this.snackbar = false;

      this.listArchivos = [];
      let aXml = {
        archivo: this.resultadoComprobante._xml,
        nombreArchivo: this.resultadoComprobante._nombre + ".xml",
        tipo: ".xml",
      };
      let aPdf = {
        archivo: this.resultadoComprobante._pdf,
        nombreArchivo: this.resultadoComprobante._nombre + ".pdf",
        tipo: ".pdf",
      };

      this.listArchivos.push(aXml);
      this.listArchivos.push(aPdf);
      axios
        .post("api/Facturas/EnviaFactura", {
          nombre: this.$store.state.usuario.usario,
          correo: this.$store.state.usuario.correo,
          asunto: this.asunto,
          archivos: this.listArchivos,
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.dialogCorreo = false;
          this.dialogPdf = false;
          this.dialogComprobante = false;
          this.error = "Comprobante enviado con éxito";
          this.snackbar = true;
          this.dialogFolio = false;
          this.dialog = false;
        })
        .catch((err) => {
          this.error = "Error al enviar, intente nuevamente";
          this.snackbar = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async timbra() {
      console.log(this.$store.state.usuario);
      this.textDialog = "Timbrando, espere...";
      this.loading = true;

      let valorIva = Math.round((this.total - this.total / 1.16) * 100) / 100;
      console.log(valorIva);

      let comprobante = {
        serie: "WEB",
        folio: "0",
        fecha: new Date(),
        receptor: {
          rfc: this.$store.state.usuario.rfc,
          nombre: this.$store.state.usuario.empresa,
          usoCfdi: "G03",
        },
        emisor: {
          rfc: "EKU9003173C9",
          nombre: "EMPRESA DEMO SA DE CV",
          regimen: "601",
          cp: "72000",
          logo: "",
        },
        tipoCambio: "1",
        moneda: "MXN",
        subTotal: Math.round((this.total / 1.16) * 100) / 100,
        total: this.total,
        descuento: 0,
        elaboro: this.$store.state.usuario.nombre,
        metodoPago: "PUE | Pago en una sola exhibición",
        formaPago: "03 | Transferencia electrónica de fondos",
        condicionPago: "",
        tipo: "I",
        clasificador: "Factura",
        cfdiRelacionados: [],
        conceptos: [
          {
            item: 1,
            cantidad: 1,
            importe: Math.round((this.total / 1.16) * 1000000) / 1000000,
            subTotal: Math.round((this.total / 1.16) * 1000000) / 1000000,
            precio: this.total,
            total: this.total,
            notas: "",
            noPredial: "",
            clave: "",
            claveSat: "84111506",
            descripcion: this.concepto,
            descripcionSat: "Servicios de facturación",
            medida: "Servicio",
            medidaSat: "E48",
            descuento: 0,
            importeIeps: 0,
            iva: valorIva,
            impuestoIva: "IVA(16%)",
            tipoIva: "Tasa",
            tasaIva: 16.0,
            ieps: 0,
            impuestoIeps: "",
            tipoIeps: "",
            tasaIeps: 0,
            isr: 0,
            impuestoIsr: "",
            tipoIsr: "",
            tasaIsr: 0,
            ivaRetenido: 0,
            impuestoIvaRetenido: "",
            tipoIvaRetenido: "",
            tasaIvaRetenido: 0,
          },
        ],
        timbreFiscal: {},
      };

      try {
        let response = await axios.post(
          "api/Comprobantes/Timbra/sistema_venta",
          comprobante
        );
        this.srcPdf = "data:application/pdf;base64," + response.data.pdf_;
        this.resultadoComprobante._xml = response.data.xml_;
        this.resultadoComprobante._pdf = response.data.pdf_;
        this.resultadoComprobante._nombre = response.data.nombre_archivo;
        this.funcionDescargaComprobante;
        this.dialogPdf = true;
        this.loading = false;
        this.error = "Timbrado correctamente";
        this.snackbar = true;
      } catch (error) {
        console.log(error);
        this.error = "Error al timbrar";
        this.snackbar = true;
      }
    },

    async timbraPG() {
      console.log(this.$store.state.usuario);
      this.textDialog = "Timbrando a publico en general, espere...";
      this.loading = true;

      let valorIva = Math.round((this.total - this.total / 1.16) * 100) / 100;
      console.log(valorIva);

      let comprobante = {
        serie: "WEB",
        folio: "0",
        fecha: new Date(),
        receptor: {
          rfc: "XAXX010101000",
          nombre: "Público en general",
          usoCfdi: "G03",
        },
        emisor: {
          rfc: "EKU9003173C9",
          nombre: "EMPRESA DEMO SA DE CV",
          regimen: "601",
          cp: "72000",
          logo: "",
        },
        tipoCambio: "1",
        moneda: "MXN",
        subTotal: Math.round((this.total / 1.16) * 100) / 100,
        total: this.total,
        descuento: 0,
        elaboro: this.$store.state.usuario.nombre,
        metodoPago: "PUE | Pago en una sola exhibición",
        formaPago: "03 | Transferencia electrónica de fondos",
        condicionPago: "",
        tipo: "I",
        clasificador: "Factura",
        cfdiRelacionados: [],
        conceptos: [
          {
            item: 1,
            cantidad: 1,
            importe: Math.round((this.total / 1.16) * 1000000) / 1000000,
            subTotal: Math.round((this.total / 1.16) * 1000000) / 1000000,
            precio: this.total,
            total: this.total,
            notas: "",
            noPredial: "",
            clave: "",
            claveSat: "84111506",
            descripcion: this.concepto,
            descripcionSat: "Servicios de facturación",
            medida: "Servicio",
            medidaSat: "E48",
            descuento: 0,
            importeIeps: 0,
            iva: valorIva,
            impuestoIva: "IVA(16%)",
            tipoIva: "Tasa",
            tasaIva: 16.0,
            ieps: 0,
            impuestoIeps: "",
            tipoIeps: "",
            tasaIeps: 0,
            isr: 0,
            impuestoIsr: "",
            tipoIsr: "",
            tasaIsr: 0,
            ivaRetenido: 0,
            impuestoIvaRetenido: "",
            tipoIvaRetenido: "",
            tasaIvaRetenido: 0,
          },
        ],
        timbreFiscal: {},
      };

      try {
        let response = await axios.post(
          "api/Comprobantes/Timbra/sistema_venta",
          comprobante
        );
        this.loading = false;
        this.dialogFolio = false;
        this.dialog = false;
        this.error = "Timbrado a publico en general";
        this.snackbar = true;
      } catch (error) {
        console.log(error);
        this.error = "Error al timbrar";
        this.snackbar = true;
      }
    },

    seleccionarPaquete(item, desc) {
      this.dialogFolio = true;
      this.total = item;
      this.concepto = desc;
    },
  },
};
</script>

<style>
</style>