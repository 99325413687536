<template>
  <v-card>
    <v-card-title> Historial </v-card-title>
    <v-card-actions> </v-card-actions>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      class="elevation-1"
    ></v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      headers: [
        { text: "FECHA", value: "fecha" },
        { text: "PAQUETE", value: "timbre" },
        { text: "TOTAL", value: "total" },
        { text: "REFERENCIA", value: "referencia" },
      ],
      items: [],
    };
  },

  mounted() {
    this.listarVentas();
  },

  methods: {
    async listarVentas() {
      try {
        let response = await axios.get(
          "api/Usuarios/VentasHist/" + this.$store.state.usuario.idusuariosApp
        );
        console.log(response.data);
        this.items = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>