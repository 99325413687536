<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Calculando Datos... Espere
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogo para recuperar contraseña-->
    <v-dialog v-model="dialogRecuperar" persistent max-width="400px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-card-title>Recuperar Contraseña</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              absolute
              right
              style="margin-right: 20px"
              color="primary"
              elevation="2"
              fab
              dark
              small
              @click="dialogRecuperar = false"
              ><v-icon dark>close</v-icon></v-btn
            >
          </v-card-actions>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-icon="person"
            v-model="r_nombre"
            label="Nombre"
          ></v-text-field>
          <v-text-field
            prepend-icon="fingerprint"
            v-model="r_rfc"
            label="RFC"
          ></v-text-field>
          <v-text-field
            prepend-icon="alternate_email"
            label="Correo"
            v-model="r_correo"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="margin-bottom: 25px; margin-right: 20px"
            color="green"
            elevation="2"
            fab
            dark
            small
            @click="recuperarContra()"
            ><v-icon dark>done</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogo para registrar nuevo usuario-->
    <v-dialog v-model="dialogRegistro" persistent max-width="800" height="100%">
      <componente-registro @CierraVentana="CierraVentanaRegistro()" />
    </v-dialog>
    <!-- login -->
    <v-main>
      <v-main fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-12 mx-auto" max-width="500">
              <v-toolbar color="#ffffff">
                <v-toolbar-title>{{ currentTitle }} </v-toolbar-title>
              </v-toolbar>
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-card-text>
                    <!-- USUARIO -->
                    <v-text-field
                      prepend-icon="person"
                      label="Usuario"
                      v-model="usuario"
                    ></v-text-field>
                    <!-- CONTRASEÑA -->
                    <v-text-field
                      :type="show1 ? 'text' : 'password'"
                      :append-icon="show1 ? 'visibility' : 'visibility_off'"
                      @click:append="show1 = !show1"
                      prepend-icon="lock"
                      label="Contraseña"
                      v-model="contra"
                    ></v-text-field>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text>
                    <v-select
                      v-model="selectEmpresa"
                      :items="itemsEmpresa"
                      item-text="nombre_e"
                      item-value="nombreBase"
                      label="Selecciona tu empresa"
                      return-object
                    ></v-select>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <v-btn
                  width="100%"
                  style="margin: auto"
                  v-show="!hiddenValida"
                  @click="validaUsuario()"
                  outlined
                  color="primary"
                  >Validar Usuario</v-btn
                >
              </v-card-actions>
              <v-card-actions>
                <v-row justify="center">
                  <v-btn
                    width="95%"
                    text
                    v-show="!hiddenValida"
                    @click="dialogRecuperar = true"
                    >Recuperar Contraseña</v-btn
                  >
                </v-row>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  width="100%"
                  style="margin: auto"
                  v-show="!hiddenValida"
                  outlined
                  color="primary"
                  @click="dialogRegistro = true"
                  >Registrarse</v-btn
                >
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  width="40%"
                  style="margin: auto"
                  v-show="hiddenValida"
                  outlined
                  color="primary"
                  @click="ingresar()"
                  >Iniciar Sesiòn</v-btn
                >
                <v-btn
                  width="40%"
                  style="margin: auto"
                  outlined
                  color="primary"
                  v-show="hiddenValida"
                  @click="step--, (hiddenValida = false)"
                  >Cambiar Usuario</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-main>
    </v-main>
    <v-snackbar
      rounded="pill"
      shaped
      color="primary light"
      v-model="snackbar"
      :timeout="timeout"
      >{{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"
          >Cerrar</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ComponenteRegistro from "../Login/Registro";
import axios from "axios";

export default {
  name: "Login",
  components: {
    ComponenteRegistro,
  },
  data: () => ({
    loading: false,
    snackbar: false,
    error: null,
    timeout: 3000,
    show1: false,
    dialogRegistro: false,
    dialogRecuperar: false,
    step: 1,
    usuario: "",
    contra: "",
    //recuperar
    r_nombre: "",
    r_rfc: "",
    r_correo: "",
    selectEmpresa: {},
    itemsEmpresa: [],
    hiddenValida: false,

    disabledEmpresa: null,
    disabledUsuario: null,
    hiddenEmpresa: false,

    idUsuario: null,
    nombreUsuario: null,
    rolUsuario: null,
    empresa: null,
    empresaBase: null,
    rfc: "",
    firma: null,
    correo: "",
  }),
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Valide su usuario";
        case 2:
          return "Seleccione su empresa";
        default:
          return "Account created";
      }
    },
  },
  methods: {
    CierraVentanaRegistro() {
      this.dialogRegistro = false;
    },
    async validaUsuario() {
      this.snackbar = false;
      if (this.usuario == "") {
        this.error = "Indique un usuario";
        this.snackbar = true;
        return;
      }
      if (this.contra == "") {
        this.error = "Indique su contraseña";
        this.snackbar = true;
        return;
      }
      this.loading = true;
      //VALIDAMOS QUE EL USUARIO SEA VALIDO
      try {
        let respuesta = await axios.post("api/Clientes/Valida", {
          nombre: this.usuario,
          password: this.contra,
        });
        this.idUsuario = respuesta.data.idUsuario_;
        this.rolUsuario = respuesta.data.rol_;
        this.firma = respuesta.data.firma_;
        this.rfc = respuesta.data.rfc_;
        this.correo = respuesta.data.correo_;
        this.listaEmpresas(respuesta.data.idUsuario_);
        this.disabledEmpresa = !this.disabledEmpresa;
        this.disabledUsuario = !this.disabledUsuario;
        this.hiddenValida = !this.hiddenValida;
        this.hiddenEmpresa = !this.hiddenEmpresa;
        this.nombreUsuario = respuesta.data.nombre_;
        this.step++;
        this.loading = false;

        console.log(respuesta.data);
      } catch (err) {
        console.log(err.response.status);

        if (err.response.status == 400) {
          this.error = "Usuario inexistente";
          this.snackbar = true;
          this.loading = false;
        } else if (err.response.status == 404) {
          this.error = "Contraseña incorrecta";
          this.snackbar = true;
          this.loading = false;
        } else if (err.response.status == 401) {
          this.error = "El usuario ya cuenta con una sesión iniciada";
          this.snackbar = true;
          this.loading = false;
        } else {
          this.error = "Datos incorrectos, verifique su usuario y contraseña";
          this.snackbar = true;
          this.loading = false;
          this.loading = false;
        }
      }
    },
    async recuperarContra() {
      this.snackbar = false;
      if (this.r_nombre == "") {
        this.error = "Indique su nombre";
        this.snackbar = true;
        return;
      }
      if (this.r_rfc == "") {
        this.error = "Indique su RFC";
        this.snackbar = true;
        return;
      }
      if (this.r_correo == "") {
        this.error = "Indique su correo";
        this.snackbar = true;
        return;
      }
      this.loading = true;

      try {
        let respuesta = await axios.post("api/Correos/EnviaCorreo/", {
          nombre: this.r_nombre,
          correo: this.r_correo,
          rfc: this.r_rfc,
        });
        this.loading = false;
        this.error = "Corro mandado correctamente";
        this.snackbar = true;
        this.dialogRecuperar = false;
      } catch (err) {
        console.log(err.response.status);

        if (err.response.status == 400) {
          this.error = "Usuario inexistente, error al mandar contraseña";
          this.snackbar = true;
          this.loading = false;
        }
      }
    },

    listaEmpresas(item) {
      axios
        .get("api/Usuarios/Empresas/" + item)
        .then((response) => {
          this.itemsEmpresa = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    ingresar() {
      this.snackbar = false;
      // this.nombreUsuario = this.nombreU;
      this.empresa = this.selectEmpresa.nombre_e;
      this.empresaBase = this.selectEmpresa.nombreBase;
      this.rfc = this.selectEmpresa.rfc;

      if (!this.selectEmpresa.nombre_e) {
        this.error = "Seleccione una empresa de la lista";
        this.snackbar = true;
        return;
      }

      axios
        .post("api/LoginTimbre/Login", {
          idUsuario: this.idUsuario,
          nombre: this.nombreUsuario,
          rol: this.rolUsuario,
          empresa: this.empresa,
          empresaBase: this.empresaBase,
          firma: this.firma,
          rfc: this.rfc,
          correo: this.correo,
        })
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .then((data) => {
          console.log(data);
          this.$store.dispatch("guardarToken", data.token);
          this.$router.push({ name: "Home" });
          this.nombre = "";
          this.password = "";
        })
        .catch((err) => {
          if (err.response.status == 500) {
            this.error = err.response;
            this.snackbar = true;
          }
        });
    },
  },
};
</script>
