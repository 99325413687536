<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Creando usuario espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-row justify="center"> -->
    <v-card>
      <v-toolbar color="primary" dark>
        <v-card-title>
          <span class="text-h5" style="padding-top: 10px"
            ><v-icon style="padding-right: 10px" black>person</v-icon>Nuevo
            Usuario</span
          >
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.usuario"
                label="Usuario"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.nombre"
                label="Nombre"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.apellidos"
                label="Apellidos"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.contraseña"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'visibility' : 'visibility_off'"
                @click:append="show1 = !show1"
                label="Contraseña"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.correo"
                label="Correo"
                type="email"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.telefono"
                label="Telèfono"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.rSocial"
                label="Razòn Social"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registro.rfc"
                label="RFC"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-select
                v-model="registro.cfdi"
                label="Uso del CFDI"
                :items="cfdi"
                required
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-btn
                style="margin-bottom: 25px; margin-right: 10px"
                color="primary"
                elevation="2"
                fab
                dark
                small
                @click="CerrarVentana()"
                ><v-icon dark>close</v-icon></v-btn
              >
              <v-btn
                style="margin-bottom: 25px; margin-right: 10px"
                color="green"
                elevation="2"
                fab
                dark
                small
                @click="crearUsuario()"
                ><v-icon dark>done</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <!-- </v-row> -->
    <v-snackbar
      rounded="pill"
      shaped
      color="primary light"
      v-model="snackbar"
      :timeout="timeout"
      >{{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"
          >Cerrar</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    loading: false,
    dialog: false,
    show1: false,
    show2: false,
    error: null,
    timeout: 3000,
    snackbar: false,

    cfdi: [
      "G01| Adquisición de mercancías",
      "G02| Devoluciones, descuentos o bonificaciones",
      "G03| Gastos en general",
      "I01| Construcciones",
      "I02| Mobiliario y equipo de oficina por inversiones",
      "I03| Equipo de transporte",
      "I04| Equipo de computo y accesorios",
      "I05| Dados, troquetes, moldes, matrices y herramental",
      "I06| Comunicaciones telefónicas",
      "I07| Comunicaciones satelitales",
      "I08| Otra maquinaria y equipo",
      "D01| Honorarios médicos, dentales y gastos hospitalarios",
      "D02| Gastos médicos por incapacidad o discapacidad",
      "D03| Gastos funerales",
      "D04| Donativos",
      "D05| Interes reales efectivamente pagados por creditos hipotecarios (casa habitación)",
      "D06| Aportaciones voluntarias al SAR",
      "D07| Primas por seguros de gastos medicos",
      "D08| Gastos de transportación escolar obligatoria",
      "D09| Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensión",
      "D10| Pagos por servicios educativos (Colegiaturas)",
      "P01| Por definir",
    ],
    registro: {
      usuario: "",
      nombre: "",
      apellidos: "",
      contraseña: "",
      correo: "",
      telefono: "",
      rSocial: "",
      rfc: "",
      cfdi: "",
    },
  }),
  computed: {},
  methods: {
    CerrarVentana() {
      this.$emit("CierraVentana");
    },
    //CREAR USUARIO
    crearUsuario() {
      this.snackbar = false;
      if (this.usuario == "") {
        this.error = "Indique su usuario";
        this.snackbar = true;
        return;
      }
      this.snackbar = false;
      if (this.nombre == "") {
        this.error = "Indique su nombre";
        this.snackbar = true;
        return;
      }
      this.snackbar = false;
      if (this.apellidos == "") {
        this.error = "Indique sus apellidos";
        this.snackbar = true;
        return;
      }
      this.snackbar = false;
      if (this.contraseña == "") {
        this.error = "Indique su contraseña";
        this.snackbar = true;
        return;
      }
      if (this.correo == "") {
        this.error = "Indique su correo";
        this.snackbar = true;
        return;
      }
      if (this.telefono == "") {
        this.error = "Indique su telefono";
        this.snackbar = true;
        return;
      }
      if (this.rSocial == "") {
        this.error = "Indique su razón social";
        this.snackbar = true;
        return;
      }
      if (this.rfc == "") {
        this.error = "Indique su RFC";
        this.snackbar = true;
        return;
      }
      if (this.cfdi == "") {
        this.error = "Seleccione el uso de CFDI";
        this.snackbar = true;
        return;
      }
      this.loading = true;
      console.log(this.registro);
      axios
        .post("api/LoginTimbre/Crear", this.registro)
        .then((data) => {
          console.log(data.data);
          this.CerrarVentana();
        })
        .catch((err) => {
          console.log(err.response.data.Message);
          this.error = err.response.data.Message;
          this.snackbar = true;
        })
        .finally(() => {
          console.log("Termino");
          this.loading = false;
        });
    },
  },
};
</script>